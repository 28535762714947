import SparkGraphCard from '../SparkGraphCard';
import { Widget } from 'types/widget';
import WidgetWrapper from '../WidgetWrapper';
import { Typography } from '@mui/material';
import useWidgetData from 'hooks/useWidgetData';
import Link from '../Link';
import { useGetTotalActiveTrucksQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const TotalActiveTrucks: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, refresh } = useWidgetData(useGetTotalActiveTrucksQuery);
  const currentActiveTruckCount = widgetData ? widgetData.currentActiveTruckCount : 0;
  // TODO: Add deactivated/activated truck count and message

  const addTruckMessage = (
    <Typography variant="caption" color="textSecondary">
      {t('Add your first truck')}{' '}
      <Typography component="span" variant="caption" sx={{ color: 'warning.main' }}>
        <Link linkPath={'/equipment'} text={t('here')} inline />.
      </Typography>
    </Typography>
  );

  return (
    <WidgetWrapper widget={TotalActiveTrucks}>
      <SparkGraphCard
        title={t('Total Active Trucks')}
        count={currentActiveTruckCount}
        countSuffix={t('Trucks')}
        percentage={undefined}
        message={currentActiveTruckCount === 0 ? addTruckMessage : undefined}
        loading={loading}
        error={error}
        refresh={refresh}
        linkPath={'/equipment'}
        analyticsWidgetName="Trucks Widget"
      />
    </WidgetWrapper>
  );
};

TotalActiveTrucks.height = 150;
TotalActiveTrucks.row = 0;
TotalActiveTrucks.sortOrder = 4;
TotalActiveTrucks.displayName = 'Total Active Trucks';
export default TotalActiveTrucks;
