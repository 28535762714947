import SparkGraphCard from '../SparkGraphCard';
import { Widget } from 'types/widget';
import WidgetWrapper from '../WidgetWrapper';
import { Typography } from '@mui/material';
import useWidgetData from 'hooks/useWidgetData';
import useSparkData from 'hooks/useSparkData';
import { useGetTotalMileageQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const TotalMileage: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, refresh } = useWidgetData(useGetTotalMileageQuery);
  const { diff, current, percentage } = useSparkData(widgetData, 'currentPeriodMileage', 'priorPeriodMileage');

  const message =
    percentage !== undefined ? (
      <Typography variant="caption" color="textSecondary">
        {t('You drove')}{' '}
        <Typography component="span" variant="caption" sx={{ color: percentage && percentage < 0 ? 'warning.main' : 'primary.main' }}>
          {percentage !== 0 && diff.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        </Typography>{' '}
        {percentage && percentage < 0 ? (
          <span>{t('fewer')}</span>
        ) : percentage === 0 ? (
          <span>{t('the same amount of')}</span>
        ) : (
          <span>{t('more')}</span>
        )}{' '}
        {t('miles this period')}.
      </Typography>
    ) : (
      <Typography variant="caption" color="textSecondary">
        {t('Find your loaded')}
      </Typography>
    );

  const tooltip = t('Total mileage is loaded');

  return (
    <WidgetWrapper widget={TotalMileage}>
      <SparkGraphCard
        title={t('Total Mileage')}
        count={current.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        percentage={percentage}
        message={message}
        tooltip={tooltip}
        loading={loading}
        error={error}
        refresh={refresh}
        isLoss={Boolean(percentage && percentage < 0)}
        color={percentage && percentage < 0 ? 'warning' : undefined}
        linkPath={'/loads'}
        analyticsWidgetName="Mileage Widget"
      />
    </WidgetWrapper>
  );
};

TotalMileage.height = 150;
TotalMileage.row = 0;
TotalMileage.sortOrder = 2;
TotalMileage.displayName = 'Total Mileage';
export default TotalMileage;
