import { Divider, Stack, Typography } from '@mui/material';
import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import IftaCard from '../IftaCard';
import { useGetIftaSummaryQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

interface WidgetData {
  quarter: number;
  dueDate: number;
  isCompleted: boolean;
}

const IftaReminder: Widget = () => {
  const { t } = useTranslation();
  const startDate = new Date().setHours(0, 0, 0, 0);
  let refetch;
  let { data: widgetData, isFetching: loading, error } = useGetIftaSummaryQuery({ startDate });
  ({ data: widgetData, isFetching: loading, error, refetch } = useGetIftaSummaryQuery({ startDate }, { manual: true }));

  return (
    <WidgetWrapper widget={IftaReminder}>
      <WidgetHeader caption={t('IFTA Summary')} linkPath={'/ifta'} analyticsWidgetName={'IFTA Widget'} />
      <MainCard
        sx={{ mt: 1.5, padding: 0 }}
        style={{ height: IftaReminder.height + 5, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        cardContentRootStyles={{ minHeight: '100%', padding: '0 0 10px 0' }}
      >
        {error && <ErrorState refresh={refetch} marginTop={'17px'} />}
        {!error && loading && <Loading />}
        {!error && !loading && widgetData && !!widgetData.length && (
          <Stack direction="column" justifyContent="space-between" height={'100%'}>
            <Stack direction="column" width={'100%'}>
              <Stack direction="row" justifyContent="space-between" padding={'20px'} height={'84px'} alignItems={'center'}>
                <Typography variant="subtitle1">{t('Quarter')}</Typography>
                <Typography variant="subtitle1" width="25%">
                  {t('Due Date')}
                </Typography>
              </Stack>
              <Divider sx={{ color: '#F0F0F0', width: '100%' }} />
            </Stack>
            {widgetData?.map((wd: WidgetData, i: number) => (
              <IftaCard key={i} quarter={wd.quarter} dueDate={wd.dueDate} orderId={i} isCompleted={wd.isCompleted} />
            ))}
          </Stack>
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

IftaReminder.height = 450;
IftaReminder.row = 2;
IftaReminder.sortOrder = 7;
IftaReminder.width = 6;
IftaReminder.displayName = 'Ifta Reminder';
export default IftaReminder;
