import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { getEndofDay, subtractDays } from '../../utils/dateUtils';

const DATE_RANGE = 13;

export interface AppState {
  startDate: Date;
  endDate: Date;
  gridWidth?: number;
}

const initialState: AppState = {
  startDate: subtractDays(new Date(), DATE_RANGE, true),
  endDate: getEndofDay(),
  gridWidth: undefined,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setEndDate: (state, action: PayloadAction<Date>) => {
      state.endDate = action.payload;
      state.startDate = subtractDays(action.payload, DATE_RANGE);
    },
    setGridWidth: (state, action: PayloadAction<number | undefined>) => {
      state.gridWidth = action.payload;
    },
    clearState: () => initialState,
  },
});

const dateOptions: any = { year: 'numeric', month: 'long', day: 'numeric' };
const formatDate = (date: Date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString('en-US', dateOptions);
  }
  return undefined;
};

export const selectStartDate = (state: RootState) => state.app.startDate;
export const selectEndDate = (state: RootState) => state.app.endDate;
export const selectFormattedStartDate = (state: RootState) => formatDate(state.app.startDate);
export const selectFormattedEndDate = (state: RootState) => formatDate(state.app.endDate);
export const selectGridWidth = (state: RootState) => state.app.gridWidth;
export const { setEndDate, setGridWidth, clearState } = appSlice.actions;
export default appSlice.reducer;
