import { FC, useEffect, useState } from 'react';
import widgets from './components/widgets';
import ResponsiveGrid from './components/ResponsiveGrid';
import { selectFormattedEndDate, selectFormattedStartDate, setEndDate, selectEndDate, clearState } from 'store/reducers/appSlice';
import { useAppSelector, useAppDispatch } from 'store';
import { Dialog, DialogTitle, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarOutlined } from '@ant-design/icons';
import { reportingApiSlice, selectApisFailed } from 'store/reducers/reportingApiSlice';
import Loading from './components/Loading';
import { ReactComponent as SomethingMissing } from '../assets/somethingMissing.svg';
import { CloseOutlined } from '@ant-design/icons';
import { sendDateChangeEvent } from 'utils/analytics';
import { getToken } from 'utils/axios';
import { getEndofDay } from 'utils/dateUtils';
import { useTranslation } from 'react-i18next';

const ConfigurableDashboard: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const token = getToken();
  const formattedStartDate = useAppSelector(selectFormattedStartDate);
  const formattedEndDate = useAppSelector(selectFormattedEndDate);
  const endDate = useAppSelector(selectEndDate);
  const allApisFailed = useAppSelector(selectApisFailed);
  const [showApiFailure, setShowApiFailure] = useState(true);
  const lsKey = 'main-layouts';
  let displayedWidgets = widgets;
  // NOTE: Only needed once users modify widgets
  // const [layouts] = useLocalStorage(lsKey);
  // if (layouts && layouts.lg) {
  //   const layoutWidgetNames = layouts.lg.map((l: any) => l.i);
  //   displayedWidgets = widgets.filter((w) => layoutWidgetNames.includes(w.displayName));
  // }

  useEffect(() => {
    dispatch(reportingApiSlice.util.resetApiState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const changeEndDate: any = (date: Date) => {
    const endDate = getEndofDay(date);
    sendDateChangeEvent(endDate);
    dispatch(setEndDate(endDate));
  };

  const onClose = () => setShowApiFailure(false);

  return (
    <div style={{ position: 'relative' }}>
      {allApisFailed && showApiFailure && (
        <>
          <div style={{ backdropFilter: 'blur(4px)', position: 'absolute', width: '100%', height: '100%', zIndex: 1 }} />
          <Loading style={{ zIndex: 2, position: 'fixed', width: '100%', top: 200, left: 0 }} />
          <Dialog
            open={allApisFailed && showApiFailure}
            onClose={onClose}
            hideBackdrop={true}
            PaperProps={{ sx: { position: 'absolute', top: 100 } }}
          >
            <DialogTitle>
              <IconButton
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
            <Stack direction={'column'} alignItems={'center'} sx={{ marginX: '125px', marginY: '70px' }}>
              <SomethingMissing />
              <Typography variant="h5" color="textSecondary" textAlign={'center'}>
                {t("We can't load your dashboard")}
              </Typography>
            </Stack>
          </Dialog>
        </>
      )}
      <div style={{ marginLeft: '30px', marginRight: '30px' }}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Typography variant="h6" color="textSecondary">
            {t('Period')}: {formattedStartDate} to {formattedEndDate}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={changeEndDate}
              mask="____-__-__"
              inputFormat={'yyyy-MM-dd'}
              disableFuture
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, style: { backgroundColor: '#FFF' } }}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              components={{ OpenPickerIcon: CalendarOutlined }}
            />
          </LocalizationProvider>
        </Stack>
        <hr />
      </div>
      <ResponsiveGrid currentWidgets={displayedWidgets} lsKey={lsKey} rowHeight={150} widgetMargin={30} />
    </div>
  );
};

export default ConfigurableDashboard;
