import { Typography, Stack } from '@mui/material';
import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import useWidgetData from 'hooks/useWidgetData';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import EmptyState from '../EmptyState';
import { useGetStatementsSummaryQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const StatementsSummary: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, refresh } = useWidgetData(useGetStatementsSummaryQuery);
  const series = [widgetData?.ownerOperatorAmount, widgetData?.companyDriverAmount];
  const total = widgetData?.companyDriverAmount + widgetData?.ownerOperatorAmount;
  const percents = [(series[0] / total) * 100, (series[1] / total) * 100];

  const options: ChartProps = {
    chart: {
      type: 'donut',
      fontFamily: `'Proxima Nova', sans-serif`,
    },
    colors: ['#FF1818', '#F5F5F5'],
    labels: [t('Owner Operators'), t('Company Drivers')],
    dataLabels: {
      style: {
        colors: ['#262626'],
        fontSize: '24px',
      },
      dropShadow: false,
      formatter: function (val: any, opts: any) {
        const { seriesIndex } = opts;
        return `$${series[seriesIndex].toFixed(0)}`;
      },
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      type: 'gradient',
    },
    plotOptions: {
      pie: {
        // NOTE: Handles the fact that size only effects chart and not the offset labels
        customScale: '0.80',
        dataLabels: {
          offset: 80,
        },
      },
    },
    tooltip: {
      custom: ({ seriesIndex }: any) => {
        return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;"><span>${percents[
          seriesIndex
        ].toFixed(0)}%</span></div>`;
      },
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.5,
        },
      },
    },
  };

  const emptyText = t('Create statements to see');

  return (
    <WidgetWrapper widget={StatementsSummary}>
      <WidgetHeader caption={t('Statements Summary')} linkPath={'/statements'} analyticsWidgetName={'Statements Widget'} />
      <MainCard
        sx={{ mt: 1.5, padding: 0 }}
        style={{ height: StatementsSummary.height + 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 0 }}
        cardContentRootStyles={{ minHeight: '100%', paddingTop: 0 }}
      >
        <div style={{ borderBottom: '1px solid #efefef', marginLeft: '-20px', marginRight: '-20px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" margin={'20px'}>
            <Typography variant="subtitle2">{t('Owner Operators vs Company Drivers Statements')}</Typography>
          </Stack>
        </div>
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && (!widgetData || total === 0) && (
          <EmptyState text={emptyText} buttonText={t('Create Statement')} linkPath={'/statements'} />
        )}
        {!error && !loading && widgetData && total !== 0 && (
          // NOTE: Adjust height based on the height class variable
          <ReactApexChart options={options} series={series} type="donut" height={StatementsSummary.height - 33} />
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

StatementsSummary.height = 600;
StatementsSummary.row = 1;
StatementsSummary.sortOrder = 2;
StatementsSummary.width = 6;
StatementsSummary.displayName = 'Statements Summary';
export default StatementsSummary;
