import { Stack, Typography } from '@mui/material';
import { Widget } from 'types/widget';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import MainCard from '../MainCard';
import useWidgetData from 'hooks/useWidgetData';
import Loading from '../Loading';
import EmptyState from '../EmptyState';
import { buildDateDataArray, createMonthsLabel } from 'utils/dateUtils';
import InfoToolTip from '../InfoToolTip';
import ErrorState from '../ErrorState';
import { useGetLoadsSummaryQuery } from '../../../store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const LoadsSummary: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, startDate, endDate, refresh } = useWidgetData(useGetLoadsSummaryQuery);
  const { days, data } = buildDateDataArray(startDate, endDate, 'count', widgetData);
  const totalCount = data.reduce((a, c) => a + c, 0);

  const series = [
    {
      name: t('Invoiced Loads'),
      data,
    },
  ];

  const options: ChartProps = {
    chart: {
      fontFamily: `'Proxima Nova', sans-serif`,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: days,
      title: {
        text: createMonthsLabel(startDate, endDate),
        style: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#888',
        },
      },
      labels: {
        style: {
          fontSize: '11px',
          lineHeight: '22px',
          color: '#8c8c8c',
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      title: {
        text: t('Invoiced Loads'),
        style: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#888',
        },
      },
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0);
        },
        style: {
          fontSize: '14px',
          lineHeight: '22px',
          color: '#8c8c8c',
          fontWeight: 500,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }: any) => {
        if (days && data && days.length > 0 && data.length > 0) {
          return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;"><span>Invoiced Loads: ${data[
            dataPointIndex
          ].toFixed(0)}</span></div>`;
        }
      },
    },
  };

  const tooltip = t('Any load that was invoiced');
  const emptyText = t('Enter your first load');

  return (
    <WidgetWrapper widget={LoadsSummary}>
      <WidgetHeader caption={t('Loads Summary')} linkPath={'/loads'} analyticsWidgetName={'Invoiced Loads Widget'} />
      <MainCard sx={{ mt: 1.5, height: LoadsSummary.height + 35 }} content={false}>
        <div style={{ borderBottom: '1px solid #efefef' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" margin={'20px'}>
            <InfoToolTip tooltip={tooltip} />
            <Typography variant="subtitle2">
              {!!totalCount && `${totalCount} `}
              {t('Invoiced Loads')}
            </Typography>
          </Stack>
        </div>
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && (!widgetData || !widgetData.length) && (
          <EmptyState text={emptyText} buttonText={t('Create Load')} linkPath={'/loads'} />
        )}
        {!error && !loading && widgetData && !!widgetData.length && (
          // NOTE: Adjust height based on the height class variable
          <ReactApexChart options={options} series={series} type="bar" height={LoadsSummary.height - 80} style={{ margin: '20px' }} />
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

LoadsSummary.height = 600;
LoadsSummary.row = 1;
LoadsSummary.sortOrder = 1;
LoadsSummary.width = 6;
LoadsSummary.displayName = 'Loads Summary';
export default LoadsSummary;
