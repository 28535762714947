import { InfoCircleOutlined } from '@ant-design/icons';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

interface Props {
  tooltip: string;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    backgroundColor: '#000000',
  },
});

const InfoToolTip = ({ tooltip }: Props) => {
  return (
    <CustomWidthTooltip title={tooltip} arrow color="#888">
      <InfoCircleOutlined />
    </CustomWidthTooltip>
  );
};

export default InfoToolTip;
