import BrokersSummary from './BrokersSummary';
import LoadsSummary from './LoadsSummary';
import BirthdayReminders from './BirthdayReminders';
import RevenueSummary from './RevenueSummary';
import FuelSummary from './FuelSummary';
import TotalActiveTrucks from './TotalActiveTrucks';
import TotalActiveDrivers from './TotalActiveDrivers';
import TotalMileage from './TotalMileage';
import TotalActiveLoads from './TotalActiveLoads';
import FuelDriverTypeSummary from './FuelDriverTypeSummary';
import StatementsSummary from './StatementsSummary';
import IftaReminder from './IftaReminder';
import Calendar from './Calendar';

const widgets = [
  TotalActiveTrucks,
  TotalActiveDrivers,
  TotalMileage,
  TotalActiveLoads,
  LoadsSummary,
  StatementsSummary,
  RevenueSummary,
  BrokersSummary,
  FuelSummary,
  FuelDriverTypeSummary,
  BirthdayReminders,
  IftaReminder,
  Calendar,
];

export {
  TotalActiveTrucks,
  TotalActiveDrivers,
  TotalMileage,
  TotalActiveLoads,
  LoadsSummary,
  StatementsSummary,
  RevenueSummary,
  BrokersSummary,
  FuelSummary,
  FuelDriverTypeSummary,
  BirthdayReminders,
  IftaReminder,
  Calendar,
};

export default widgets;
