import SparkGraphCard from '../SparkGraphCard';
import { Widget } from 'types/widget';
import WidgetWrapper from '../WidgetWrapper';
import { Typography } from '@mui/material';
import useWidgetData from 'hooks/useWidgetData';
import Link from '../Link';
import { useGetTotalActiveDriversQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const TotalActiveDrivers: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, refresh } = useWidgetData(useGetTotalActiveDriversQuery);
  const currentActiveDriverCount = widgetData ? widgetData.currentActiveDriverCount : 0;
  // TODO: Add deactivated/activated driver count and message

  const addDriverMessage = (
    <Typography variant="caption" color="textSecondary">
      {t('Add your first driver')}{' '}
      <Typography component="span" variant="caption" sx={{ color: 'warning.main' }}>
        <Link linkPath={'/myfleet'} text={t('here')} inline />.
      </Typography>
    </Typography>
  );

  return (
    <WidgetWrapper widget={TotalActiveDrivers}>
      <SparkGraphCard
        title={t('Total Active Drivers')}
        count={currentActiveDriverCount}
        countSuffix={t('Drivers')}
        percentage={undefined}
        message={currentActiveDriverCount === 0 ? addDriverMessage : undefined}
        loading={loading}
        error={error}
        refresh={refresh}
        linkPath={'/myfleet'}
        analyticsWidgetName={'Drivers Widget'}
      />
    </WidgetWrapper>
  );
};

TotalActiveDrivers.height = 150;
TotalActiveDrivers.row = 0;
TotalActiveDrivers.sortOrder = 3;
TotalActiveDrivers.displayName = 'Total Active Drivers';
export default TotalActiveDrivers;
