import { Widget } from 'types/widget';
import { getWidgetPixelHeight } from 'utils/gridUtils';

interface Props {
  height?: string;
  widget?: Widget;
  style?: any;
  children: any;
}

const WidgetWrapper = ({ height, widget, style, children }: Props) => {
  const finalHeight = height || getWidgetPixelHeight(widget);
  return <div style={{ ...style, height: finalHeight, minHeight: finalHeight }}>{children}</div>;
};

export default WidgetWrapper;
