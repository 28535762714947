import { useEffect, useState } from 'react';

export default function useSparkData(widgetData: any, currentKey: string, priorKey: string) {
  const [diff, setDiff] = useState(0);
  const [current, setCurrent] = useState(0);
  const [percentage, setPercentage] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (widgetData && currentKey in widgetData && priorKey in widgetData) {
      const curr = widgetData[currentKey];
      const prior = widgetData[priorKey];
      setCurrent(curr);
      const more = curr - prior;
      setDiff(more);
      if (curr === 0 && prior === 0) {
        setPercentage(undefined);
      } else {
        const percent = Number(((more / prior) * 100).toFixed(1));
        setPercentage(percent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData]);

  return { diff: Math.abs(diff), current, percentage };
}
