import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import { getWidgetPixelHeight } from 'utils/gridUtils';
import { useState } from 'react';
import { addDays } from 'utils/dateUtils';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import { Stack, Grid } from '@mui/material';
import Birthday from '../Birthday';
import EmptyState from '../EmptyState';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { navigateToPath } from '../Link';
import { selectGridWidth } from 'store/reducers/appSlice';
import { useAppSelector } from 'store';
import { splitEvery } from 'ramda';
import { useGetBirthdaysQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const DATE_RANGE = 14;
const LIMIT = 14;
const CARD_PADDING = 20;
const AVATAR_WIDTH = 84;
const GRID_MARGIN = 30;

interface WidgetData {
  firstName: string;
  lastName?: string;
  birthday: string;
  profileImageKey?: string;
}

const BirthdayReminders: Widget = () => {
  const { t } = useTranslation();
  const startDate = new Date().setHours(0, 0, 0, 0);
  const endDate = addDays(new Date(), DATE_RANGE).setHours(0, 0, 0, 0);
  let refetch;
  let { data: widgetData, isFetching, error } = useGetBirthdaysQuery({ startDate, endDate });
  ({ data: widgetData, isFetching, error, refetch } = useGetBirthdaysQuery({ startDate, endDate }, { manual: true }));
  const gridWidth: number | undefined = useAppSelector(selectGridWidth);
  const availableSpace = gridWidth ? gridWidth - CARD_PADDING * 2 - GRID_MARGIN * 2 : 0;
  let maxBirthdays = gridWidth ? Math.floor(availableSpace / AVATAR_WIDTH) : LIMIT;
  const [page, setPage] = useState(0);
  const birthdays = widgetData?.slice(0, LIMIT);
  let pages = splitEvery(maxBirthdays, birthdays || []);
  const remainingSpace = availableSpace - AVATAR_WIDTH * maxBirthdays;
  if (pages.length > 1 && remainingSpace < 30) {
    maxBirthdays = maxBirthdays - 1;
    pages = splitEvery(maxBirthdays, birthdays || []);
  }

  const emptyText = t('Add your drivers birthday');

  return (
    <WidgetWrapper widget={BirthdayReminders}>
      <WidgetHeader caption={t('Birthday Reminders')} linkPath={'/myfleet'} analyticsWidgetName={'Birthday Widget'} />
      <MainCard
        style={{ height: getWidgetPixelHeight(BirthdayReminders) - 30 }}
        cardContentRootStyles={{ minHeight: '100%', padding: `${CARD_PADDING}px` }}
      >
        {error && <ErrorState refresh={refetch} inline marginTop={'17px'} />}
        {!error && isFetching && <Loading />}
        {!error && !isFetching && (!birthdays || !birthdays.length) && (
          <EmptyState text={emptyText} buttonText={t('Add Birthdays to My Fleet')} linkPath={'/myfleet'} inline={true} marginTop={'17px'} />
        )}
        <Grid container columns={20} minWidth={'100%'}>
          {pages.length > 1 && page > 0 && (
            <Grid item xs={1} display={'flex'}>
              <CaretLeftOutlined style={{ fontSize: '30px', color: '#ccc', alignSelf: 'center' }} onClick={() => setPage(page - 1)} />
            </Grid>
          )}
          {!error && !isFetching && birthdays && !!birthdays.length && (
            <Grid item xs={pages.length > 2 && page !== 0 && page !== pages.length - 1 ? 18 : 19}>
              <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} spacing={3}>
                {/* @ts-ignore */}
                {pages[page].map((wd: WidgetData, i: number) => (
                  <Birthday
                    birthday={wd.birthday}
                    profileImageKey={wd.profileImageKey}
                    key={i}
                    firstName={wd.firstName}
                    lastName={wd.lastName}
                    onClick={() => navigateToPath('/myfleet')}
                  />
                ))}
              </Stack>
            </Grid>
          )}
          {pages.length > 1 && page < pages.length - 1 && (
            <Grid item xs={1} display={'flex'}>
              <CaretRightOutlined style={{ fontSize: '30px', color: '#ccc', alignSelf: 'center' }} onClick={() => setPage(page + 1)} />
            </Grid>
          )}
        </Grid>
      </MainCard>
    </WidgetWrapper>
  );
};

BirthdayReminders.height = 150;
BirthdayReminders.row = 3;
BirthdayReminders.sortOrder = 1;
BirthdayReminders.displayName = 'Birthday Reminders';
export default BirthdayReminders;
