import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import useWidgetData from 'hooks/useWidgetData';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import React from 'react';
import EmptyState from '../EmptyState';
import GallonAmountSelectorButtonGroup from '../GallonAmountSelectorButtonGroup';
import { useGetFuelByDriverTypeQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const FuelDriverTypeSummary: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, refresh, widgetData } = useWidgetData(useGetFuelByDriverTypeQuery);
  const [showAmount, setShowAmount] = React.useState(true);
  const gallonsTotal = widgetData?.ownerOperatorGallons + widgetData?.companyDriverGallons + widgetData?.unassignedGallons;
  const amountTotal = widgetData?.ownerOperatorCost + widgetData?.companyDriverCost + widgetData?.unassignedCost;
  const total = showAmount ? amountTotal : gallonsTotal;

  const emptyText = t("Comparing drivers' and operators'");

  const seriesData = [
    showAmount ? widgetData?.ownerOperatorCost : widgetData?.ownerOperatorGallons,
    showAmount ? widgetData?.companyDriverCost : widgetData?.companyDriverGallons,
    showAmount ? widgetData?.unassignedCost : widgetData?.unassignedGallons,
  ];
  const options: ChartProps = {
    series: seriesData,
    labels: [t('Owner Operators'), t('Company Drivers'), t('Unassigned')],
    colors: ['#457528', '#72BF44', '#B5DD9D'],
    dataLabels: {
      style: {
        colors: ['#FFFFFF'],
        fontSize: '16px',
      },
      dropShadow: false,
      formatter: function (value: number, opt: any) {
        return showAmount ? '$' + opt.w.config.series[opt.seriesIndex] : opt.w.config.series[opt.seriesIndex] + ' gal';
      },
    },
    legend: { position: 'bottom', showForSingleSeries: true },
    tooltip: {
      custom: ({ seriesIndex }: any) => {
        if (seriesData && seriesData.length && seriesData[seriesIndex] !== undefined) {
          let percentValue = ((seriesData[seriesIndex] / total) * 100).toFixed(2);
          return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;">
            <span>${percentValue}%</span></div>`;
        } else {
          return ``;
        }
      },
    },
  };

  return (
    <WidgetWrapper widget={FuelDriverTypeSummary}>
      <WidgetHeader
        caption={t('Owner Operators vs. Company Drivers Fuel')}
        linkPath={'/fuel'}
        analyticsWidgetName={'Driver Fuel Widget'}
        children={<GallonAmountSelectorButtonGroup showAmount={showAmount} setShowAmount={setShowAmount} isSummary={false} />}
      />
      <MainCard
        sx={{ mt: 1.5 }}
        style={{ height: FuelDriverTypeSummary.height + 27, display: 'flex', flexDirection: 'column' }}
        cardContentRootStyles={{ minHeight: '100%' }}
      >
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && widgetData && gallonsTotal === 0 && amountTotal === 0 && (
          <EmptyState text={emptyText} buttonText={t('Add Fuel Entry')} linkPath={'/fuel'} />
        )}
        {/* NOTE: Adjust height based on the height class variable */}
        {!error && !loading && widgetData && (
          <>
            <ReactApexChart options={options} series={options.series} type="pie" height={FuelDriverTypeSummary.height + 15} />
          </>
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

FuelDriverTypeSummary.height = 600;
FuelDriverTypeSummary.row = 1;
FuelDriverTypeSummary.sortOrder = 6;
FuelDriverTypeSummary.width = 6;
FuelDriverTypeSummary.displayName = 'Owner Operators vs Company Drivers';
export default FuelDriverTypeSummary;
