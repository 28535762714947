import { Stack, Typography } from '@mui/material';
import { getMonthName } from 'utils/dateUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  quarter: number;
  dueDate: number;
  orderId: number;
  isCompleted: boolean;
}

const IftaCard = ({ quarter, dueDate, orderId, isCompleted }: Props) => {
  const iftaDueDate = new Date(dueDate);
  const isCurrent = orderId === 1;
  const { t } = useTranslation();

  const getQuarterName = () => {
    if (isCurrent) {
      return t('Upcoming due date');
    } else {
      switch (quarter) {
        case 1:
          return t('First quarter');
        case 2:
          return t('Second quarter');
        case 3:
          return t('Third quarter');
        case 4:
          return t('Fourth quarter');
        default:
          return '';
      }
    }
  };

  const getQuarterDaysLeft = () => {
    if ((isCurrent && !isCompleted) || orderId > 1) {
      const diff = iftaDueDate.getTime() - new Date().valueOf();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      return `${diffDays} ${t('Days left')}`;
    } else {
      return '';
    }
  };

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" padding={'20px'} width={'100%'}>
      <Typography variant="h6">{getQuarterName()}</Typography>
      <Stack direction="column" width={'25%'}>
        <Typography variant="h6" color="textSecondary">
          {getMonthName(iftaDueDate)} {iftaDueDate.getDate()}
        </Typography>
        <Typography fontSize={'10px'} color={isCurrent ? '#A11B0C' : '#444444'} height={'22px'}>
          {getQuarterDaysLeft()}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default IftaCard;
