import { Box, Typography, Button, Stack } from '@mui/material';
import { navigateToPath } from './Link';

interface Props {
  text: string;
  buttonText: string;
  linkPath: string;
  inline?: boolean;
  marginTop?: string;
}

const EmptyState = ({ text, buttonText, linkPath, inline, marginTop }: Props) => {
  const onClick = () => navigateToPath(linkPath);

  return (
    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} marginTop={marginTop}>
      <Box sx={{ width: inline ? '98%' : '50%' }}>
        <Stack direction={inline ? 'row' : 'column'} spacing={3} alignItems={'center'} justifyContent={inline ? 'space-between' : 'normal'}>
          <Typography variant="h5" color="textSecondary">
            {text}
          </Typography>
          <Button variant="contained" sx={{ fontWeight: 500, borderRadius: 0, width: 'fit-content' }} onClick={onClick}>
            {buttonText}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EmptyState;
