import { Provider } from 'react-redux';
import { store } from './store';
import ConfigurableDashboard from 'dashboard/ConfigurableDashboard';
import ThemeCustomization from './themes';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import './App.scss';
import { I18nextProvider } from 'react-i18next';
import { addReportingLanguageResources } from './i18n';
import { useEffect, useState } from 'react';
import * as firebaseAnalytics from 'constants/analytics';

const ShamrockCoreConfig = require('ShamrockCoreConfig');

const App = (props: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLanguageFilesLoaded, setIsLanguageFilesLoaded] = useState(false);

  useEffect(() => {
    if (!isLanguageFilesLoaded && props.i18n) {
      addReportingLanguageResources(props.i18n);
    }

    const init = () => {
      firebaseAnalytics.init(ShamrockCoreConfig.firebaseConfig);
      console.log(ShamrockCoreConfig.firebaseConfig);
      setIsLanguageFilesLoaded(true);
      setIsLoaded(true);
    };

    init();
  }, [props.i18n, isLanguageFilesLoaded]);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={props.i18n}>
        {isLoaded && (
          <ThemeCustomization>
            <div style={{ margin: '60px' }}>
              <ConfigurableDashboard />
            </div>
          </ThemeCustomization>
        )}
      </I18nextProvider>
    </Provider>
  );
};

export default App;
