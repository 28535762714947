import { initializeApp } from 'firebase/app';
import * as firebaseAnalytics from 'firebase/analytics';

let analytics: firebaseAnalytics.Analytics;

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export const init = (firebaseConfig: FirebaseConfigProps) => {
  const app = initializeApp(firebaseConfig);
  analytics = firebaseAnalytics.getAnalytics(app);
};

export default class FirebaseAnalytics {
  static get MODULES() {
    return {
      ENTERPRISE: 'Enterprise',
    };
  }

  static get EVENTS() {
    return {
      EDIT: 'edit',
      CLICK: 'click',
    };
  }

  static get PAGES() {
    return {
      DASHBOARD: 'Dashboard',
    };
  }

  static sendEvent(event: string, module: string, page: string, customObject?: Object) {
    const payload: any = customObject || {};
    payload['module'] = module;
    payload['page'] = page;

    firebaseAnalytics?.logEvent(analytics, event, payload);
  }
}
