import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import { default as ReactCalendar } from 'react-calendar';
import { TileClassNameFunc } from 'react-calendar/dist/cjs/shared/types';
import { datesAreSameDay } from 'utils/dateUtils';
import { useEffect, useState } from 'react';
import { useGetIftaSummaryQuery } from 'store/reducers/reportingApiSlice';
// NOTE: Styles for calendar in App.scss

const Calendar: Widget = () => {
  const startDate = new Date().setHours(0, 0, 0, 0);
  const [iftaDates, setIftaDates] = useState<any[] | undefined>(undefined);
  const { data } = useGetIftaSummaryQuery({ startDate });

  useEffect(() => {
    if (data) {
      const ifta = data.reduce((a: [], id: any) => {
        if (!id.isCompleted) {
          return [...a, new Date(id.dueDate)];
        }
        return a;
      }, []);
      setIftaDates(ifta);
    }
  }, [data]);

  const tileClassName: TileClassNameFunc = ({ date }) => {
    if (iftaDates?.find((ifta) => datesAreSameDay(ifta, new Date(date)))) {
      return 'ifta_date';
    }
    return undefined;
  };

  return (
    <WidgetWrapper widget={Calendar}>
      <MainCard
        sx={{ mt: 1.5, padding: 0 }}
        style={{
          height: Calendar.height + 58.5,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 0,
          marginTop: 0,
        }}
        cardContentRootStyles={{ minHeight: '100%', padding: 0 }}
      >
        <ReactCalendar
          value={new Date()}
          tileClassName={tileClassName}
          showFixedNumberOfWeeks={true}
          tileDisabled={() => true}
          minDetail={'month'}
          maxDetail={'month'}
          view={'month'}
        />
      </MainCard>
    </WidgetWrapper>
  );
};

Calendar.height = 450;
Calendar.row = 2;
Calendar.sortOrder = 8;
Calendar.width = 6;
Calendar.displayName = 'Calendar';
export default Calendar;
