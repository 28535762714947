export const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() + days);
  return dateCopy;
};

export const subtractDays = (date: Date, days: number, midnight?: boolean) => {
  const dateCopy = new Date(date);
  if (midnight) {
    dateCopy.setHours(0, 0, 0, 0);
  }
  dateCopy.setDate(dateCopy.getDate() - days);
  return dateCopy;
};

export const getMonthName = (date: Date) => {
  return date.toLocaleString('default', { month: 'long' });
};

export const createMonthsLabel = (startDate: Date, endDate: Date) => {
  if (startDate.getMonth() === endDate.getMonth()) {
    return getMonthName(startDate);
  }
  return `${getMonthName(startDate)} / ${getMonthName(endDate)}`;
};

export const dateArrayFromRange = (startDate: Date, endDate: Date) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export const buildDateDataArray = (startDate: Date, endDate: Date, dataKey: string, data?: any[]) => {
  const days = data?.map((wd) => new Date(wd.summaryDate).getDate());
  const dateArray = dateArrayFromRange(startDate, endDate);
  const results = dateArray.map((d) => {
    const date = d.getDate();
    // NOTE: This will only work with ranges less than 28 days
    if (days?.includes(date) && data && data.length) {
      const index = days.findIndex((d) => d === date);
      return data[index][dataKey];
    }
    return 0;
  });
  return {
    data: results,
    days: dateArray.map((d) => d.getDate()),
  };
};

export const datesAreSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();

export const getEndofDay = (date?: Date) => {
  const now = date ? date : new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
};
