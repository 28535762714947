import axios from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError, AxiosInstance } from 'axios';
import LocalAuth from '@shamrock-core/common/authentication/local-authentication';

export const getToken: any = () => {
  const token = window.localStorage.getItem(LocalAuth.TOKEN_KEY);
  if (token) {
    const json = JSON.parse(token);
    return json.token;
  }
  return null;
};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const axiosServices = () => {
  const services = axios.create();
  services.interceptors.response.use(
    (response) => response,
    // NOTE: If we send back structured errors from the service
    // All error handling could happen here including launching necessary snackbars
    (error) => Promise.reject((error.response && error.response.data) || 'Service Error'),
  );
  return services;
};

export const axiosBaseQuery =
  (
    axiosInstance: AxiosInstance,
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const createBaseQuery = () => {
  const axiosInstance: AxiosInstance = axiosServices();
  return axiosBaseQuery(axiosInstance);
};

export default axiosServices;
