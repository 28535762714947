import { Box, Typography, Stack, Button } from '@mui/material';
import { ReactComponent as SomethingWrong } from '../../assets/somethingWrong.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  refresh?: any;
  compact?: boolean;
  inline?: boolean;
  marginTop?: string;
}

const ErrorState = ({ refresh, compact, inline, marginTop }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} marginTop={marginTop}>
      <Box sx={{ width: compact || inline ? '98%' : '50%' }}>
        <Stack direction={inline ? 'row' : 'column'} spacing={3} alignItems={'center'} justifyContent={inline ? 'space-between' : 'normal'}>
          {!compact && !inline && <SomethingWrong />}
          <Typography variant="h5" color="textSecondary">
            {t("We can't load your data now.")}
          </Typography>
          {refresh && (
            <Button variant="contained" sx={{ fontWeight: 500, borderRadius: 0, width: 'fit-content' }} onClick={refresh}>
              {t('Try Again')}
            </Button>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default ErrorState;
