import { CircularProgress, Stack } from '@mui/material';

interface Props {
  style?: any;
}

const Loading = ({ style }: Props) => {
  return (
    <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} height={'100%'} style={style}>
      <CircularProgress size={100} />
    </Stack>
  );
};

export default Loading;
