import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Link from './Link';

interface Props {
  caption: string;
  linkPath: string;
  analyticsWidgetName: string;
  children?: ReactNode;
}

const WidgetHeader = ({ caption, linkPath, analyticsWidgetName, children }: Props) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '25px' }}>
      <Grid item>
        <Typography variant="h3">
          <Link text={caption} linkPath={linkPath} analyticsWidgetName={analyticsWidgetName} />
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default WidgetHeader;
