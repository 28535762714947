import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import GallonAmountSelectorButtonGroup from '../GallonAmountSelectorButtonGroup';
import React from 'react';
import useWidgetData from 'hooks/useWidgetData';
import { buildDateDataArray, createMonthsLabel } from 'utils/dateUtils';
import ErrorState from '../ErrorState';
import EmptyState from '../EmptyState';
import Loading from '../Loading';
import { useGetFuelSummaryQuery } from 'store/reducers/reportingApiSlice';
import { getMoneyFormat } from 'utils/numberUtils';
import { useTranslation } from 'react-i18next';

const FuelSummary: Widget = () => {
  const { t } = useTranslation();
  const [showAmount, setShowAmount] = React.useState(true);
  const { error, loading, refresh, widgetData, startDate, endDate } = useWidgetData(useGetFuelSummaryQuery);
  const { days, data } = buildDateDataArray(startDate, endDate, showAmount ? 'amount' : 'gallons', widgetData);

  const emptyStateText = t('Record your fuel transactions');
  const emptyStateButtonText = t('Create New Entry');
  const emptyStateLinkPath = '/fuel';

  var options: ChartProps = {
    series: [{ data: data }],
    chart: {
      type: 'area',
      height: 350,
      fontFamily: `'Proxima Nova', sans-serif`,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    labels: days,
    xaxis: {
      categories: days,
      title: {
        text: createMonthsLabel(startDate, endDate),
        style: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#888',
        },
      },
    },
    yaxis: {
      opposite: false,
      labels: {
        show: true,
        formatter: function (val: any) {
          return showAmount ? '$' + val.toFixed(2) : val.toFixed(0) + ' gal';
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }: any) => {
        if (days && data && days.length > 0 && data.length > 0) {
          let toolTipDisplay = showAmount ? getMoneyFormat(data[dataPointIndex]) : data[dataPointIndex] + ` ${t('gallons')}`;
          return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;"><span>${toolTipDisplay}</span></div>`;
        }
      },
    },
    colors: ['#72BF44'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
  };

  return (
    <WidgetWrapper widget={FuelSummary}>
      <WidgetHeader
        caption={t('Fuel Summary')}
        linkPath={'/fuel'}
        analyticsWidgetName={'Fuel Widget'}
        children={<GallonAmountSelectorButtonGroup showAmount={showAmount} setShowAmount={setShowAmount} isSummary={true} />}
      />
      <MainCard sx={{ mt: 1.5, height: FuelSummary.height + 27 }} content={false}>
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && (!widgetData || widgetData.length === 0) && (
          <EmptyState text={emptyStateText} buttonText={emptyStateButtonText} linkPath={emptyStateLinkPath} />
        )}
        {!error && !loading && widgetData && widgetData.length > 0 && (
          <>
            {/* NOTE: Adjust height based on the height class variable */}
            <ReactApexChart
              options={options}
              series={options.series}
              type="area"
              height={FuelSummary.height - 20}
              style={{ margin: '20px' }}
            />
          </>
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

FuelSummary.height = 600;
FuelSummary.row = 1;
FuelSummary.sortOrder = 5;
FuelSummary.width = 6;
FuelSummary.displayName = 'Fuel Summary';
export default FuelSummary;
