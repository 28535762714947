import { sendWidgetClickEvent } from 'utils/analytics';

interface Props {
  text: string;
  linkPath: string;
  inline?: boolean;
  analyticsWidgetName?: string;
}

export const navigateToPath = (linkPath: string, analyticsWidgetName?: string) => {
  if (analyticsWidgetName) {
    sendWidgetClickEvent(analyticsWidgetName);
  }
  window.history.pushState({}, '', linkPath);
  const navEvent = new PopStateEvent('popstate');
  window.dispatchEvent(navEvent);
};

const Link = ({ linkPath, text, inline, analyticsWidgetName }: Props) => {
  return inline ? (
    <span style={{ cursor: 'pointer' }} onClick={() => navigateToPath(linkPath, analyticsWidgetName)}>
      {text}
    </span>
  ) : (
    <div style={{ cursor: 'pointer' }} onClick={() => navigateToPath(linkPath, analyticsWidgetName)}>
      {text}
    </div>
  );
};

export default Link;
