import { Fragment, useMemo, useRef, useState, useEffect } from 'react';
import { Responsive } from 'react-grid-layout';
import useLocalStorage from '../../hooks/useLocalStorage';
import { staticLayouts } from '../../utils/gridUtils';
import { Widget } from 'types/widget';
import { useResizeDetector } from 'react-resize-detector';
import { useDispatch } from 'react-redux';
import { setGridWidth } from 'store/reducers/appSlice';

interface Props {
  currentWidgets: Widget[];
  lsKey: string;
  rowHeight: number;
  widgetMargin: number;
}

const ResponsiveGrid = ({ currentWidgets, lsKey, rowHeight, widgetMargin }: Props) => {
  const largeColumns = 12;
  const breakpoints = { lg: 1200, md: 996, xs: 480, xxs: 0 };
  const cols = { lg: largeColumns, md: 6, xs: 3, xxs: 2 };
  const dispatch = useDispatch();
  // NOTE: Only needed once users modify widgets, then remove the other useLocalStorage
  // const initialLayout = useMemo(() => generateLayout(currentWidgets, largeColumns, rowHeight), [rowHeight, currentWidgets]);
  // const [layouts, setLayouts] = useLocalStorage(lsKey, { lg: initialLayout });
  const [layouts, setLayouts] = useLocalStorage(lsKey, staticLayouts);
  const [currentBP, setCurrentBP] = useState('lg');
  const initialWidth = window.innerWidth - 120;
  const gridRef: any = useRef();
  const { width } = useResizeDetector({
    targetRef: gridRef,
  });

  useEffect(() => {
    // NOTE: Only needed once users modify widgets and add initialLayout to dependency array
    // setLayouts({ lg: initialLayout });
    setLayouts(staticLayouts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setGridWidth(width));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const onLayoutChange = (currentLayout: any, allLayouts: any) => {
    setLayouts(allLayouts);
  };

  const onBreakpointChange = (newBreakpoint: string, newCols: number) => {
    setCurrentBP(newBreakpoint);
  };

  function GetWidget({ displayName }: { displayName: string }) {
    const widget = currentWidgets.find((w) => w.displayName === displayName);
    return widget ? widget({}) : <Fragment />;
  }

  const grid = useMemo(() => {
    return (
      layouts[currentBP] &&
      layouts[currentBP].map((l: any) => (
        <div key={l.i} data-grid={l} className="widget">
          <GetWidget displayName={l.i} />
        </div>
      ))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layouts, currentBP]);

  return (
    <div ref={gridRef}>
      <Responsive
        className="layout"
        layouts={layouts}
        breakpoints={breakpoints}
        cols={cols}
        rowHeight={rowHeight}
        width={width || initialWidth}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        compactType={'vertical'}
        isDraggable={false}
        isResizable={false}
        margin={[widgetMargin, widgetMargin]}
      >
        {grid}
      </Responsive>
    </div>
  );
};

export default ResponsiveGrid;
