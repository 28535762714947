import { Widget } from 'types/widget';
import { uniq, ascend, compose, prop, sort } from 'ramda';

// NOTE: These should be the same as set for rowHeight and widgetMargin in ResponsiveGrid
const rowHeight = 150;
const widgetMargin = 30;
const bySortOrder = ascend(compose(prop('sortOrder')));

export const generateLayoutRow = (widgets: Widget[], columns: number, rowPixelHeight: number, runningRowHeight: number) => {
  const widgetHeight = widgets[0].height;
  const widgetNames = widgets.map((w) => w.displayName);
  const w = Math.floor(columns / widgetNames.length);
  let y = runningRowHeight;
  const initialLayout = widgetNames.map((widget, i) => {
    let x = i * (widgets[i].width || w);
    if (x >= columns) {
      y = runningRowHeight + Math.floor(widgetHeight / rowPixelHeight) * Math.floor(x / columns);
      x = x % columns;
    }
    return {
      i: widget,
      x,
      y,
      w: widgets[i].width || w,
      h: Math.floor(widgetHeight / rowPixelHeight),
    };
  });
  return initialLayout;
};

export const generateLayout = (widgets: Widget[], columns: number, rowPixelHeight: number) => {
  const rows = uniq(widgets.map((w) => w.row));
  const heights: number[] = [];
  let layouts: any[] = [];
  rows.forEach((r) => {
    const w = sort(
      bySortOrder,
      widgets.filter((w) => w.row === r),
    );
    const runningRowHeight = heights.reduce((a, c) => a + c, 0);
    const l = generateLayoutRow(w, columns, rowPixelHeight, runningRowHeight);
    const widgetHeight = w[0].height;
    const oneRow = Math.floor(widgetHeight / rowPixelHeight);
    const totalWidth = w.reduce((a, c) => a + (c.width || 0), 0);
    const h = oneRow * ((totalWidth || columns) / columns);
    heights.push(h);
    layouts = layouts.concat(l);
  });
  return layouts;
};

export const lostRow = (allWidgets: Widget[], widget: Widget, layouts: any) => {
  // @ts-ignore
  const rowWidgetNames = allWidgets.reduce((a, c) => {
    if (c.row === widget.row && c.displayName !== widget.displayName) {
      return [...a, c.displayName];
    }
    return a;
  }, []);
  const layoutNames = layouts.lg.map((l: any) => l.i);
  // @ts-ignore
  return !rowWidgetNames.some((r) => layoutNames.indexOf(r) >= 0);
};

export const getWidgetPixelHeight = (widget?: Widget) => {
  if (widget) {
    const rowCount = widget.height / rowHeight;
    const height = rowHeight * rowCount + widgetMargin * (rowCount - 1);
    return height;
  }
  return 0;
};

export const staticLayouts = {
  lg: [
    { w: 3, h: 1, x: 0, y: 0, i: 'Total Active Loads' },
    { w: 3, h: 1, x: 3, y: 0, i: 'Total Mileage' },
    { w: 3, h: 1, x: 6, y: 0, i: 'Total Active Drivers' },
    { w: 3, h: 1, x: 9, y: 0, i: 'Total Active Trucks' },
    { w: 6, h: 4, x: 0, y: 1, i: 'Loads Summary' },
    { w: 6, h: 4, x: 6, y: 1, i: 'Statements Summary' },
    { w: 6, h: 4, x: 0, y: 5, i: 'Revenue Summary' },
    { w: 6, h: 4, x: 6, y: 5, i: 'Brokers Summary' },
    { w: 6, h: 4, x: 0, y: 9, i: 'Fuel Summary' },
    { w: 6, h: 4, x: 6, y: 9, i: 'Owner Operators vs Company Drivers' },
    { w: 6, h: 3, x: 0, y: 13, i: 'Ifta Reminder' },
    { w: 6, h: 3, x: 6, y: 13, i: 'Calendar' },
    { w: 12, h: 1, x: 0, y: 16, i: 'Birthday Reminders' },
  ],
  md: [
    { w: 3, h: 1, x: 0, y: 0, i: 'Total Active Loads' },
    { w: 3, h: 1, x: 3, y: 0, i: 'Total Mileage' },
    { w: 3, h: 1, x: 0, y: 1, i: 'Total Active Drivers' },
    { w: 3, h: 1, x: 3, y: 1, i: 'Total Active Trucks' },
    { w: 6, h: 4, x: 0, y: 2, i: 'Loads Summary' },
    { w: 6, h: 4, x: 0, y: 6, i: 'Statements Summary' },
    { w: 6, h: 4, x: 0, y: 10, i: 'Revenue Summary' },
    { w: 6, h: 4, x: 0, y: 14, i: 'Brokers Summary' },
    { w: 6, h: 4, x: 0, y: 18, i: 'Fuel Summary' },
    { w: 6, h: 4, x: 0, y: 23, i: 'Owner Operators vs Company Drivers' },
    { w: 6, h: 3, x: 0, y: 26, i: 'Ifta Reminder' },
    { w: 6, h: 3, x: 0, y: 29, i: 'Calendar' },
    { w: 6, h: 1, x: 0, y: 32, i: 'Birthday Reminders' },
  ],
};
