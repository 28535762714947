import { Stack, Typography } from '@mui/material';
import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import useWidgetData from 'hooks/useWidgetData';
import { buildDateDataArray, createMonthsLabel } from 'utils/dateUtils';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import EmptyState from '../EmptyState';
import InfoToolTip from '../InfoToolTip';
import { useGetRevenueSummaryQuery } from 'store/reducers/reportingApiSlice';
import { getMoneyFormat } from 'utils/numberUtils';
import { useTranslation } from 'react-i18next';

const RevenueSummary: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, startDate, widgetData, endDate, refresh } = useWidgetData(useGetRevenueSummaryQuery);
  const { days, data } = buildDateDataArray(startDate, endDate, 'total', widgetData);
  const total = data
    .reduce((a, c) => a + c, 0)
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})$)/g, '$1,');

  const series = [
    {
      name: t('Total Revenue'),
      data: data,
    },
  ];

  const options: ChartProps = {
    chart: {
      fontFamily: `'Proxima Nova', sans-serif`,
      toolbar: {
        show: false,
      },
    },
    colors: ['#FF9000'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: days,
      title: {
        text: createMonthsLabel(startDate, endDate),
        style: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#888',
        },
      },
      labels: {
        style: {
          fontSize: '11px',
          lineHeight: '22px',
          color: '#8c8c8c',
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      title: {
        text: t('Total Revenue'),
        style: {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#888',
        },
      },
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0).replace(/(\d)(?=(\d{3})$)/g, '$1,');
        },
        style: {
          fontSize: '14px',
          lineHeight: '22px',
          color: '#8c8c8c',
          fontWeight: 500,
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }: any) => {
        if (days && data && days.length > 0 && data.length > 0) {
          return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;"><span>${getMoneyFormat(
            data[dataPointIndex],
          )}</span></div>`;
        }
      },
    },
  };

  const tooltip = t('Total Revenue is the value');
  const emptyText = t('Revenue is a key indicator');

  return (
    <WidgetWrapper widget={RevenueSummary}>
      <WidgetHeader caption={t('Revenue Summary')} linkPath={'/loads'} analyticsWidgetName={'Revenue Widget'} />
      <MainCard sx={{ mt: 2, height: RevenueSummary.height + 35 }} content={false}>
        <div style={{ borderBottom: '1px solid #efefef' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" margin={'20px'}>
            <InfoToolTip tooltip={tooltip} />
            <Typography variant="subtitle2">{total !== '0' && `$${total}`}</Typography>
          </Stack>
        </div>
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && (!widgetData || !widgetData.length) && (
          <EmptyState text={emptyText} buttonText={t('Add First Load')} linkPath={'/loads'} />
        )}
        {!error && !loading && widgetData && !!widgetData.length && (
          // NOTE: Adjust height based on the height class variable
          <ReactApexChart options={options} series={series} type="line" height={RevenueSummary.height - 80} style={{ margin: '20px' }} />
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

RevenueSummary.height = 600;
RevenueSummary.row = 1;
RevenueSummary.sortOrder = 3;
RevenueSummary.width = 6;
RevenueSummary.displayName = 'Revenue Summary';
export default RevenueSummary;
