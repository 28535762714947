import FirebaseAnalytics from 'constants/analytics';

export const sendDateChangeEvent = (date: Date) => {
  FirebaseAnalytics.sendEvent(FirebaseAnalytics.EVENTS.EDIT, FirebaseAnalytics.MODULES.ENTERPRISE, FirebaseAnalytics.PAGES.DASHBOARD, {
    label: 'Date Range',
    selected_date: date?.toLocaleDateString(),
  });
};

export const sendWidgetClickEvent = (widgetName: string) => {
  FirebaseAnalytics.sendEvent(FirebaseAnalytics.EVENTS.CLICK, FirebaseAnalytics.MODULES.ENTERPRISE, FirebaseAnalytics.PAGES.DASHBOARD, {
    label: widgetName,
  });
};
