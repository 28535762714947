import { Stack, Typography } from '@mui/material';
import { Widget } from 'types/widget';
import MainCard from '../MainCard';
import WidgetWrapper from '../WidgetWrapper';
import WidgetHeader from '../WidgetHeader';
import ReactApexChart, { Props as ChartProps } from 'react-apexcharts';
import useWidgetData from 'hooks/useWidgetData';
import ErrorState from '../ErrorState';
import Loading from '../Loading';
import EmptyState from '../EmptyState';
import { useAppSelector } from 'store';
import { selectGridWidth } from 'store/reducers/appSlice';
import { useGetBrokersSummaryQuery } from 'store/reducers/reportingApiSlice';
import { getMoneyFormat } from 'utils/numberUtils';
import { useTranslation } from 'react-i18next';

const MAX_LABEL_LENGTH = 30;

interface BrokerData {
  name: string;
  invoiceCount: number;
  invoicePercentage: number;
  invoiceTotalAmount: number;
}

const BrokersSummary: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, widgetData, refresh } = useWidgetData(useGetBrokersSummaryQuery);
  const total = widgetData?.reduce((a: number, c: BrokerData) => a + c.invoiceCount, 0);
  const labels = widgetData?.map((wd: BrokerData) => {
    let label = '';
    if (wd.name) {
      const trimmed = wd.name.replace(/\([^()]*\)/g, '');
      label = trimmed.slice(0, MAX_LABEL_LENGTH);
      if (trimmed.length > MAX_LABEL_LENGTH) {
        label = `${label}&hellip;`;
      }
    }
    return label;
  });
  const series = widgetData?.map((wd: BrokerData) => wd.invoicePercentage);
  const amounts = widgetData?.map((wd: BrokerData) => wd.invoiceTotalAmount);
  const gridWidth = useAppSelector(selectGridWidth);
  const gridPadding = gridWidth ? window.innerWidth - gridWidth : 0;

  const options: ChartProps = {
    chart: {
      fontFamily: `'Proxima Nova', sans-serif`,
      type: 'pie',
    },
    colors: ['#003251', '#005C95', '#0091EA', '#62C3FF', '#EAF7FF', '#457528', '#72BF44', '#B5DD9D', '#FFD86F', '#FFEFC4'],
    labels: labels,
    dataLabels: {
      style: {
        colors: ['#999'],
        fontSize: '14px',
      },
      dropShadow: false,
    },
    legend: {
      position: 'right',
    },
    responsive: [
      {
        breakpoint: 1730 + gridPadding,
        options: {
          legend: {
            position: 'bottom',
          },
          plotOptions: {
            pie: {
              customScale: '0.8',
            },
          },
        },
      },
      {
        breakpoint: 1200 + gridPadding,
        options: {
          legend: {
            position: 'right',
          },
        },
      },
      {
        breakpoint: 1000 + gridPadding,
        options: {
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        // NOTE: Handles the fact that size only effects chart and not the offset labels
        customScale: '0.9',
        dataLabels: {
          offset: 80,
        },
      },
    },
    tooltip: {
      custom: ({ seriesIndex }: any) => {
        if (amounts && amounts.length && amounts[seriesIndex] !== undefined && labels) {
          return `<div class="arrow_box" style="padding: 5px; font-size: 14px; color: #FFFFFF; background-color: #000000;"><span>${
            labels[seriesIndex]
          }: ${getMoneyFormat(amounts[seriesIndex])}</span></div>`;
        }
      },
    },
  };

  const emptyText = t('After deliver your first load');

  return (
    <WidgetWrapper widget={BrokersSummary}>
      <WidgetHeader caption={t('Brokers Summary')} linkPath={'/loads'} analyticsWidgetName={'Broker Invoices Widget'} />
      <MainCard
        sx={{ mt: 1.5, padding: 0 }}
        style={{ height: BrokersSummary.height + 35, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 0 }}
        cardContentRootStyles={{ minHeight: '100%', paddingTop: 0 }}
      >
        <div style={{ borderBottom: '1px solid #efefef', marginLeft: '-20px', marginRight: '-20px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" margin={'20px'}>
            <Typography variant="subtitle2">{t('Top Broker Invoice Volume')}</Typography>
            <Typography variant="subtitle2">{!!total && `${total}`}</Typography>
          </Stack>
        </div>
        {error && <ErrorState refresh={refresh} />}
        {!error && loading && <Loading />}
        {!error && !loading && (!widgetData || !widgetData.length) && (
          <EmptyState text={emptyText} buttonText={t('Take me to Loads')} linkPath={'/loads'} />
        )}
        {!error && !loading && widgetData && !!widgetData.length && (
          // NOTE: Adjust height based on the height class variable
          <ReactApexChart options={options} series={series} type="pie" height={BrokersSummary.height - 33} />
        )}
      </MainCard>
    </WidgetWrapper>
  );
};

BrokersSummary.height = 600;
BrokersSummary.row = 1;
BrokersSummary.sortOrder = 4;
BrokersSummary.width = 6;
BrokersSummary.displayName = 'Brokers Summary';
export default BrokersSummary;
