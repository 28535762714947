import SparkGraphCard from '../SparkGraphCard';
import { Widget } from 'types/widget';
import WidgetWrapper from '../WidgetWrapper';
import { Typography } from '@mui/material';
import useWidgetData from 'hooks/useWidgetData';
import useSparkData from 'hooks/useSparkData';
import { useGetTotalActiveLoadsQuery } from 'store/reducers/reportingApiSlice';
import { useTranslation } from 'react-i18next';

const TotalActiveLoads: Widget = () => {
  const { t } = useTranslation();
  const { error, loading, refresh, widgetData } = useWidgetData(useGetTotalActiveLoadsQuery);
  const { diff, current, percentage } = useSparkData(widgetData, 'activeLoads', 'priorPeriodActiveLoads');

  const message =
    percentage !== undefined ? (
      <Typography variant="caption" color="textSecondary">
        {t('There are')}{' '}
        <Typography component="span" variant="caption" sx={{ color: percentage && percentage < 0 ? 'warning.main' : 'primary.main' }}>
          {percentage !== 0 && diff}
        </Typography>{' '}
        {percentage && percentage < 0 ? (
          <span>{t('fewer')}</span>
        ) : percentage === 0 ? (
          <span>{t('the same amount of')}</span>
        ) : (
          <span>{t('more')}</span>
        )}{' '}
        {t('loads this period')}.
      </Typography>
    ) : (
      <Typography variant="caption" color="textSecondary">
        {t('Find your active load count here')}
      </Typography>
    );

  const tooltip = t('Active Loads are loads');

  return (
    <WidgetWrapper widget={TotalActiveLoads}>
      <SparkGraphCard
        title={t('Total Active Loads')}
        count={current}
        percentage={percentage}
        message={message}
        tooltip={tooltip}
        loading={loading}
        error={error}
        refresh={refresh}
        isLoss={Boolean(percentage && percentage < 0)}
        color={percentage && percentage < 0 ? 'warning' : undefined}
        linkPath={'/loads'}
        analyticsWidgetName="Loads Widget"
      />
    </WidgetWrapper>
  );
};

TotalActiveLoads.height = 150;
TotalActiveLoads.row = 0;
TotalActiveLoads.sortOrder = 1;
TotalActiveLoads.displayName = 'Total Active Loads';
export default TotalActiveLoads;
