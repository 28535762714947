import i18n, { i18n as i18nType } from 'i18next';
import en from './translations/en.json';
import es from './translations/es.json';

export const resources = {
  en,
  es,
};

export const addReportingLanguageResources = (i18nFromShell: i18nType) => {
  i18nFromShell.addResources('en', 'common', en.common);
  i18nFromShell.addResources('es', 'common', es.common);
};

// only use this for unit tests
export const initializeI18nForUnitTest = () => {
  i18n.init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en',
  });
  return i18n;
};

export type SupportedLanguages = keyof typeof resources;
