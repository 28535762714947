import { Box, Chip, ChipProps, Grid, Stack, Typography } from '@mui/material';
import MainCard from './MainCard';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import InfoToolTip from './InfoToolTip';
import Loading from './Loading';
import ErrorState from './ErrorState';
import Link from './Link';

interface Props {
  title: string;
  count: string | number;
  percentage?: number;
  isLoss?: boolean;
  color?: ChipProps['color'];
  message: any;
  tooltip?: string;
  loading?: boolean;
  error?: boolean;
  refresh?: any;
  linkPath?: string;
  countSuffix?: string;
  analyticsWidgetName: string;
}

const SparkGraphCard = ({
  color = 'primary',
  title,
  count,
  percentage,
  isLoss,
  message,
  tooltip,
  loading,
  error,
  refresh,
  linkPath,
  countSuffix,
  analyticsWidgetName,
}: Props) => (
  <MainCard contentSX={{ p: 2.25 }} style={{ height: '100%' }}>
    {loading && <Loading />}
    {error && refresh && <ErrorState refresh={refresh} compact />}
    {!loading && !error && (
      <>
        <Stack spacing={0.5}>
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography variant="h6" color="textSecondary">
              {linkPath ? <Link text={title} linkPath={linkPath} analyticsWidgetName={analyticsWidgetName} /> : title}
            </Typography>
            {tooltip && <InfoToolTip tooltip={tooltip} />}
          </Stack>
          <Grid container alignItems="center">
            <Grid item>
              <Typography variant="h4" color="inherit">
                {count} {!!countSuffix && countSuffix}
              </Typography>
            </Grid>
            {percentage !== undefined && (
              <Grid item>
                <Chip
                  variant="combined"
                  color={color}
                  icon={
                    <>
                      {!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                      {isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                    </>
                  }
                  label={`${percentage}%`}
                  sx={{ ml: 1.25, pl: 1 }}
                  size="small"
                />
              </Grid>
            )}
          </Grid>
        </Stack>
        <Box sx={{ pt: 2.25 }}>{message}</Box>
      </>
    )}
  </MainCard>
);

export default SparkGraphCard;
