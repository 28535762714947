import React, { Dispatch, SetStateAction } from 'react';
import { Button } from '@mui/material';
import { sendWidgetClickEvent } from 'utils/analytics';
import { useTranslation } from 'react-i18next';

interface GallonAmountSelectorButtonGroupProps {
  showAmount: boolean;
  setShowAmount: Dispatch<SetStateAction<boolean>>;
  isSummary: boolean;
}

const GallonAmountSelectorButtonGroup: React.FC<GallonAmountSelectorButtonGroupProps> = ({ showAmount, setShowAmount, isSummary }) => {
  const { t } = useTranslation();
  const onClick: any = (showAmount: boolean) => {
    const eventName = [isSummary ? 'Fuel' : 'Driver Fuel', showAmount ? 'Amount' : 'Gallons'].join(' ');
    sendWidgetClickEvent(eventName);
    setShowAmount(showAmount);
  };

  return (
    <>
      <Button
        variant={showAmount ? 'outlined' : 'text'}
        color={showAmount ? 'primary' : 'secondary'}
        sx={{ fontWeight: 500, width: 'fit-content' }}
        onClick={() => onClick(true)}
      >
        {t('Amount')}
      </Button>
      <Button
        variant={!showAmount ? 'outlined' : 'text'}
        color={!showAmount ? 'primary' : 'secondary'}
        sx={{ fontWeight: 500, width: 'fit-content' }}
        onClick={() => onClick(false)}
      >
        {t('Gallons')}
      </Button>
    </>
  );
};

export default GallonAmountSelectorButtonGroup;
