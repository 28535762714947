import { useAppSelector } from 'store';
import { selectEndDate, selectStartDate } from 'store/reducers/appSlice';

export default function useWidgetData(serviceCall: Function) {
  const endDate = useAppSelector(selectEndDate);
  const startDate = useAppSelector(selectStartDate);
  let refetch;
  let {
    data: widgetData,
    isSuccess,
    isError,
    isFetching: loading,
    error,
  } = serviceCall({ startDate: startDate.getTime(), endDate: endDate.getTime() });

  const refresh = async () => {
    ({
      data: widgetData,
      isFetching: loading,
      isSuccess,
      isError,
      error,
      refetch,
    } = serviceCall({ startDate: startDate.getTime(), endDate: endDate.getTime() }, { manual: true }));

    refetch();
  };

  return { isSuccess, isError, error, loading, widgetData, startDate, endDate, refresh };
}
