import { Avatar, Stack, Typography, Tooltip, tooltipClasses, TooltipProps, styled } from '@mui/material';
import { useGetPresignedUrlQuery } from 'store/reducers/reportingApiSlice';
const mime = require('mime');

interface Props {
  birthday: string;
  firstName: string;
  lastName?: string;
  profileImageKey?: string;
  onClick: any;
}

const Birthday = ({ birthday, profileImageKey, firstName, lastName, onClick }: Props) => {
  const mimeType = mime.getType(profileImageKey);
  const body = {
    key: profileImageKey,
    name: profileImageKey,
    type: mimeType || 'application/octet-stream',
    isUpload: false,
    isStaged: false,
    isView: true,
  };
  const { data } = useGetPresignedUrlQuery(body, { skip: !profileImageKey });
  const birthDate = new Date(birthday);

  const getWholeName = () => {
    if (lastName) {
      return `${firstName} ${lastName}`;
    }
    return firstName;
  };

  const CustomColorTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#000000',
    },
  });

  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
        <CustomColorTooltip title={getWholeName()} arrow color="#FFFFFF" placement="top">
          <Avatar alt={getWholeName()} src={data && data.url ? data.url : undefined} sx={{ width: 60, height: 60 }} />
        </CustomColorTooltip>
        <Typography variant="subtitle2" fontStyle={{ whiteSpace: 'nowrap' }}>
          {birthDate.toLocaleString('default', { month: 'short' })} {birthDate.getDate()}
        </Typography>
      </Stack>
    </div>
  );
};

export default Birthday;
